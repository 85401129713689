import axios from "axios";
import axiosRetry from "axios-retry";
import urljoin from "url-join";
import store from "store";
import { v4 as uuid } from "uuid";

const DEBUG = process.env.NODE_ENV !== "production";

const CONFIG = {
    baseUrl: DEBUG
        ? `http://${window.location.hostname}:9090/`
        : "https://adventurestore.me/",
    timeoutMs: 10 * 1000,
    storyId: null,
    assetId: null,
    baseUrlKey: "fl-base-url",
    trackingIdKey: "fl-session",
};

const client = axios.create();
axiosRetry(client, {
    retries: 5,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: axiosRetry.isNetworkOrIdempotentRequestError,
    shouldResetTimeout: true,
});

export function getBaseUrl() {
    return store.get(CONFIG.baseUrlKey) || CONFIG.baseUrl;
}

export function configure(newConfig) {
    Object.assign(CONFIG, newConfig);
}

export function getIpInfo() {
    return apiCall("ip-info", "get", {
        timingLabel: "getIpInfo",
    });
}

export function storyCreateAnonymousSession(options) {
    const { storyId } = options || {};
    const actualStoryId = storyId || CONFIG.storyId;
    if (!actualStoryId) {
        return Promise.reject();
    }

    return apiCall(`story/${actualStoryId}/create-session`, "put", {
        timingLabel: "storyCreateAnonymousSession",
    });
}

export function storyInteract({
    interactionType,
    storyId,
    assetId,
    sessionId,
    data,
}) {
    const actualStoryId = storyId || CONFIG.storyId;
    return apiCall(`story/${actualStoryId}/interact`, "put", {
        data: {
            asset_id: assetId || CONFIG.assetId,
            interaction_type: interactionType,
            session_id: sessionId || CONFIG.sessionId,
            data: data || {},
        },
        timingLabel: "storyInteract",
    });
}

export function storyPipelineStep({ step }) {
    return apiCall(`story/${CONFIG.storyId}/pstep/${step}`, "put", {
        timingLabel: "storyPipelineStep",
        failSilently: true,
    });
}

export function storyFeedback({ storyId, text, assetId, sessionId }) {
    return apiCall(`story/${storyId}/feedback`, "put", {
        data: {
            text,
            asset_id: assetId || CONFIG.assetId,
            session_id: sessionId || CONFIG.sessionId,
        },
        timingLabel: "storyFeedback",
    });
}

export function getClientTrackingId() {
    let id = store.get(CONFIG.trackingIdKey);
    if (!id) {
        id = uuid();
        store.set(CONFIG.trackingIdKey, id);
    }
    return id;
}

export function apiCall(url, method, options) {
    const { params, data, noRetry, timingLabel, extra, failSilently } =
        options || {};
    let { headers } = options || {};

    const extraOptions = extra || {};
    if (noRetry) {
        extraOptions["axios-retry"] = {
            retries: 0,
        };
    }

    headers = headers || {};
    headers["X-FL-TID"] = getClientTrackingId();

    let promise = client.request({
        url: urljoin(getBaseUrl(), "/api", url),
        method,
        timeout: CONFIG.timeoutMs,
        params,
        data,
        timingLabel,
        headers,
        ...extraOptions,
    });

    if (failSilently) {
        promise = promise.catch(() => {
            // TODO: analytics event?
        });
    }

    return promise;
}
