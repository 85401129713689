import showdown from "showdown";

const converters = {};

export function markdownToHtml(markdown, options) {
	return getMarkdownConverter(options || {})
		.makeHtml(markdown || "")
		.replace(/…/g, "...");
}

export function markdownToText(markdown, options) {
	const elem = document.createElement("DIV");
	elem.innerHTML = markdownToHtml(markdown, options);
	return elem.textContent || elem.innerText || markdown;
}

function getMarkdownConverter({ autoLinks, downloadFileTypes }) {
	const converterKey = [
		!!autoLinks,
		(downloadFileTypes || []).join(","),
	].join("|");

	if (!converters[converterKey]) {
		const extensions = [
			downloadFileTypes
				? downloadFilesExtensionFactory(downloadFileTypes)
				: null,
		];
		const converter = new showdown.Converter({
			extensions: extensions.filter((x) => !!x),
		});
		converter.setFlavor("github");
		converter.setOption("simplifiedAutoLink", !!autoLinks);
		converter.setOption("excludeTrailingPunctuationFromURLs", true);
		converter.setOption("underline", true);
		converter.setOption("openLinksInNewWindow", true);
		converter.setOption("ghMentions", false);
		converters[converterKey] = converter;
	}

	return converters[converterKey];
}

function downloadFilesExtensionFactory(downloadFileTypes) {
	const linkRegex = new RegExp(
		`<a\\s+[^>]*href="[^"]*\\.(${downloadFileTypes.join("|")})"`,
		"gi"
	);

	return () => {
		const downloadFiles = {
			type: "output",
			filter: (text) => text.replace(linkRegex, "$& download"),
		};
		return [downloadFiles];
	};
}
