import styles from "./Widget.module.css";

import React, { useState, useEffect, useRef, useCallback } from "react";
import { createPortal } from "react-dom";
import classnames from "classnames";
import Loader from "react-feather/dist/icons/loader";

const DEFAULT_POSITION = "bottomRight";

const DEBUG = process.env.NODE_ENV !== "production";
const IS_DEPLOYED = window.location.hostname.includes("adventurestore.me");

const BASE_URL = IS_DEPLOYED
	? `https://${window.location.hostname}`
	: DEBUG
	? `http://${window.location.hostname}:4001`
	: "https://adventurestore.me";

export default function Widget({ config, storyConfig, context, onChatClick }) {
	const [dialogOpen, toggleDialog] = useState(false);

	const fullConfig = Object.assign({}, config || {}, {
		story: storyConfig || null,
	});

	return (
		<Portal>
			<Dialog
				visible={dialogOpen}
				config={fullConfig}
				userContext={context}
				onClose={() => toggleDialog(false)}
			/>
			<Bubble
				open={dialogOpen}
				config={fullConfig}
				onClick={() => toggleDialog(!dialogOpen)}
			/>
		</Portal>
	);
}

function Portal({ children }) {
	return createPortal(children, document.body);
}

function Bubble({ open, config, onClick }) {
	const { bubble, position } = config;

	return (
		<div
			className={classnames(
				styles.bubble,
				bubble?.className,
				open ? styles.open : null,
				styles[position || DEFAULT_POSITION]
			)}
			onClick={onClick}
		>
			{open ? "CLOSE" : "HELP"}
		</div>
	);
}

function Dialog({ visible, config, userContext, onClose }) {
	const [loading, setLoading] = useState(true);
	const { position } = config;

	const handleFrameLoaded = useCallback(() => setLoading(false), []);

	return (
		<div
			className={classnames(
				styles.dialog,
				visible ? styles.visible : null,
				styles[position || DEFAULT_POSITION]
			)}
		>
			{loading ? <Spinner /> : null}
			<ContentsFrame
				config={config}
				userContext={userContext}
				onLoaded={handleFrameLoaded}
			/>
		</div>
	);
}

function ContentsFrame({ config, userContext, onLoaded }) {
	const [frameSize, setFrameSize] = useState({});
	const frameRef = useRef();
	const gotHelloRef = useRef(false);

	const frameUrl = `${BASE_URL}/widget`;

	useEffect(() => {
		function handleMessage(event) {
			if (event.origin !== BASE_URL) {
				return;
			}

			if (typeof event.data !== "object" || !event.data.widget) {
				return;
			}

			const data = event.data.widget;

			if (data.hello) {
				gotHelloRef.current = true;
				onLoaded && onLoaded();
				postMessageToFrame(frameRef, {
					config,
					context: { path: getCurrentPath(), ...userContext },
				});
			}
			if (data.height && data.height > 150) {
				setFrameSize({ height: data.height });
			}
		}
		window.addEventListener("message", handleMessage, false);

		return () =>
			window.removeEventListener("message", handleMessage, false);
	}, [config, onLoaded]); // eslint-disable-line

	useEffect(() => {
		return observeHrefChanges({
			onChange: (href) =>
				postMessageToFrame(frameRef, {
					context: { path: href },
				}),
		});
	}, []);

	useEffect(() => {
		if (gotHelloRef.current) {
			postMessageToFrame(frameRef, {
				context: userContext,
			});
		}
	}, [userContext]);

	return (
		<iframe
			className={styles.frame}
			src={frameUrl}
			ref={frameRef}
			style={frameSize}
			title="Widget"
			allowFullScreen={false}
			allowpaymentrequest="true"
			loading="eager"
			referrerPolicy="origin-when-cross-origin"
			onLoad={onLoaded}
		/>
	);
}

function Spinner() {
	return (
		<div className={styles.spinner}>
			<Loader className={styles.spinner__icon} size={50} />
		</div>
	);
}

function postMessageToFrame(frameRef, data) {
	if (!frameRef.current?.contentWindow) {
		console.warn("No content window");
		return;
	}

	frameRef.current.contentWindow.postMessage({ widget: data }, BASE_URL);
}

function getCurrentPath() {
	return window.location.href;
}

function observeHrefChanges({ onChange }) {
	// based on https://stackoverflow.com/a/46428962/1795244

	let oldHref = document.location.href;

	const observer = new MutationObserver((mutations) => {
		mutations.forEach((mutation) => {
			if (oldHref !== document.location.href) {
				oldHref = document.location.href;
				onChange && onChange(document.location.href);
			}
		});
	});

	observer.observe(document.querySelector("body"), {
		childList: true,
		subtree: true,
	});

	return () => observer.disconnect();
}
