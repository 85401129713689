import { markdownToHtml } from "./MarkdownUtils";

import React, { useRef, useEffect } from "react";

export default function MarkdownContent({
	markdown,
	options,
	className,
	onLinkClick,
}) {
	const root = useRef(null);

	useEffect(() => {
		if (onLinkClick) {
			const links = root.current?.getElementsByTagName("a") || [];
			for (let i = 0; i < links.length; i += 1) {
				links[i].addEventListener("click", onLinkClick);
			}

			return () => {
				for (let i = 0; i < links.length; i += 1) {
					links[i].removeEventListener("click", onLinkClick);
				}
			};
		}
		return null;
	});

	return (
		<div
			ref={root}
			className={className}
			dangerouslySetInnerHTML={{
				__html: markdownToHtml(markdown, options), // TODO: normalizeEmojis
			}}
		/>
	);
}
