import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const ENABLE = process.env.NODE_ENV === "production";

const DEFAULT_DSN =
	"https://4b71efc1010b4399b92e68c20cdde060@o574655.ingest.sentry.io/6000292";

export function initSentry(options) {
	const { dsn, tracesSampleRate = 0.01 } = options || {};

	if (!ENABLE) {
		return;
	}
	Sentry.init({
		dsn: dsn || DEFAULT_DSN,
		integrations: [new Integrations.BrowserTracing()],
		tracesSampleRate,
	});
}
